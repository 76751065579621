import classNames from "classnames";
import styles from "../styles/historyTable.module.scss";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { Column, useTable } from "react-table";
import { InksInfosDto } from "../domain/ink.interface";
import { InkType } from "../inkList";
import { formatDate } from "../domain/format-date";
import InfiniteScroll from "react-infinite-scroll-component";

const HistoryTable = ({
  inks,
  inkTypes,
  updateData,
  hasMore,
}: {
  inks: InksInfosDto[];
  inkTypes: InkType[] | undefined;
  updateData: () => void;
  hasMore: boolean;
}) => {
  const { t, i18n } = useTranslation();

  const columns: Column<InksInfosDto>[] = useMemo(
    () => [
      {
        Header: "PN",
        accessor: "pn",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    rows,

    prepareRow,
  } = useTable({
    columns,
    data: inks,
  });

  return (
    <table
      className={classNames(styles.historyTable_section)}
      {...getTableProps()}
    >
      <thead className={classNames(styles.historyTable_section_sort, "col-12")}>
        <tr className={classNames(styles.historyTable_padding)}>
          <th className={classNames(styles.historyTable_sort, "col-1")}>
            {t("PN")}
          </th>
          <th className={classNames(styles.historyTable_sort, "col-1")}>
            {t("batch")}
          </th>
          <th className={classNames(styles.historyTable_sort, "col-1")}>
            {t("type")}
          </th>
          <th className={classNames(styles.historyTable_sort, "col-2")}>
            {t("production_date")}
          </th>
          <th className={classNames(styles.historyTable_sort, "col-2")}>
            {t("expiration_date")}
          </th>
          <th className={classNames(styles.historyTable_sort, "col-2")}>
            {t("activation_date")}
          </th>
          <th className={classNames(styles.historyTable_sort, "col-1")}>
            {t("status")}
          </th>
          <th className={classNames(styles.historyTable_sort, "col-2")}>
            {t("created_by")}
          </th>
        </tr>
      </thead>
      <tbody
        id="scrollableTBody"
        className={classNames(styles.historyTable_content, "col-12 row")}
        {...getTableBodyProps()}
      >
        <InfiniteScroll
          scrollableTarget="scrollableTBody"
          dataLength={rows.length}
          next={updateData}
          hasMore={hasMore}
          loader={<h4>Loading more items...</h4>}
        >
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                key={row.index}
                className={classNames(styles.historyTable_inks, "col-12 row")}
              >
                <td className={classNames(styles.historyTable_row, "col-1")}>
                  {row.original.pn}
                </td>
                <td className={classNames(styles.historyTable_row, "col-1")}>
                  {row.original.sn}
                </td>
                <td className={classNames(styles.historyTable_row, "col-1")}>
                  {inkTypes &&
                    inkTypes.find((i) => i.PN === row.original.pn)?.type}
                </td>
                <td className={classNames(styles.historyTable_row, "col-2")}>
                  {formatDate(
                    new Date(row.original.productionDate),
                    i18n.language,
                    "long",
                    false,
                    false
                  )}
                </td>
                <td className={classNames(styles.historyTable_row, "col-2")}>
                  {formatDate(
                    new Date(row.original.expirationDate),
                    i18n.language,
                    "long",
                    false,
                    false
                  )}
                </td>
                <td className={classNames(styles.historyTable_row, "col-2")}>
                  {row.original.activationDate
                    ? formatDate(
                        new Date(row.original.activationDate),
                        i18n.language,
                        "long",
                        false,
                        false
                      )
                    : "N/A"}
                </td>
                <td className={classNames(styles.historyTable_row, "col-1")}>
                  {t(row.original.status)}
                </td>
                <td className={classNames(styles.historyTable_row, "col-2")}>
                  {row.original.email}
                </td>
              </tr>
            );
          })}
        </InfiniteScroll>
      </tbody>
    </table>
  );
};
export default HistoryTable;
