import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "../styles/createlabel.module.scss";
import help from "../img/help.svg";
import defaultLabel from "../img/defaultLabel.png";
import tagimg from "../img/icons/tagimg.svg";
import ink from "../img/ink.svg";
import { LabelPdf } from "./LabelPdf";
import { InkType } from "../inkList";
import Select from "react-select";
import { useBackend } from "../backend/BackendProvider";
import { useInks } from "../hooks/useInks";
import Loading from "./Loading";

const Createlabel = () => {
  const { t } = useTranslation();

  const [PN, setPN] = useState<string>();

  const [inkLabel, setInkLabel] = useState<InkType>();

  const [productionDate, setProductionDate] = useState<string>(
    new Date().toISOString().split("T")[0]
  );
  const [expireDate, setExpireDate] = useState<Date>();
  const [copyNumber, setCopyNumber] = useState<number>(1);

  const [generatedPdf, setGeneratedPdf] = useState<string>();

  const [loading, setLoading] = useState<{
    loader: boolean;
    textLoader: string;
  }>({ loader: false, textLoader: "" });

  const { data: inkList } = useInks();

  useEffect(() => {
    if (inkList) {
      const newInkLabel = inkList.find(
        (i: { PN: string | undefined }) => i.PN === PN
      );
      if (newInkLabel) {
        const newDate = new Date().toISOString().split("T")[0];
        setInkLabel(newInkLabel);
        setProductionDate(newDate);
        setExpireDate(
          dateWithDelay(
            newInkLabel.lifeTime,
            new Date(newDate),
            newInkLabel.lifeTimeType
          )
        );
      }
    }
  }, [PN, inkList]);

  const onDateChange = (e: any) => {
    setProductionDate(e.target.value);
  };

  useEffect(() => {
    if (inkList) {
      const newInkLabel = inkList.find(
        (i: { PN: string | undefined }) => i.PN === PN
      );
      if (newInkLabel) {
        productionDate &&
          setExpireDate(
            dateWithDelay(
              newInkLabel.lifeTime,
              new Date(productionDate),
              newInkLabel.lifeTimeType
            )
          );
      }
    }
  }, [PN, inkList, productionDate]);

  const backend = useBackend();

  const [checked, setChecked] = useState<boolean>(false);

  const handleChecked = () => {
    setChecked(!checked);
  };

  const generateInk = useCallback(async () => {
    setLoading({ loader: true, textLoader: t("loading_product") });
    if (inkLabel && productionDate && expireDate) {
      try {
        await backend
          .createInk({
            pn: inkLabel?.PN,
            productionDate: new Date(productionDate),
            expirationDate: expireDate,
            volume: parseInt(inkLabel.volume.split("L")[0]),
            copyNumber: !isNaN(copyNumber) ? copyNumber : 1,
            inkType: inkLabel.letter,
            ssd: checked,
          })
          .then((v) => {
            LabelPdf(
              inkLabel,
              v,
              new Date(productionDate),
              expireDate,
              true,
              copyNumber,
              loading,
              setLoading
            );
          });
      } catch (error) {
        throw new Error(error as string);
      }
    }
    setLoading({ loader: true, textLoader: t("loading_document") });
  }, [
    t,
    inkLabel,
    productionDate,
    expireDate,
    backend,
    copyNumber,
    checked,
    loading,
  ]);

  const generatePdfCallBack = useCallback(async () => {
    if (inkLabel && productionDate && expireDate) {
      const generated = await LabelPdf(
        {
          ...inkLabel,
        },
        [
          { sn: "XXXXXX1", key: "XXXXXX1", uuid: "XXXX-XXXX-XXXX" },
          { sn: "XXXXXX2", key: "XXXXXX2", uuid: "XXXX-XXXX-XXXX" },
        ],
        new Date(productionDate),
        expireDate,
        false,
        1,
        loading,
        setLoading
      );
      setGeneratedPdf(generated);
    }
  }, [expireDate, inkLabel, loading, productionDate]);

  useEffect(() => {
    generatePdfCallBack();
  }, [generatePdfCallBack, PN]);

  return (
    <>
      <div className={classNames(styles.container)}>
        {loading.loader && <Loading loadingText={loading.textLoader} />}

        <div className={classNames(styles.addinfos, "col-6")}>
          <div className={classNames(styles.addpn, "col-12")}>
            <div className={classNames(styles.titlehelp, "col-12")}>
              <p>P/N :</p>
              <div>
                <img src={help} alt="Help" />
              </div>
            </div>
            <div className={classNames(styles.contentpn, "col-12")}>
              {/* <input type="text" placeholder="14083S" /> */}
              <Select
                options={inkList?.map((i) => {
                  return { value: i.PN, label: `${i.PN} - ${i.name}` };
                })}
                className={classNames(styles.choosepn)}
                styles={{
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected
                      ? "#464FED"
                      : state.isFocused
                      ? "#3e3e70"
                      : "black",
                    color: "white",

                    // backgroundColor: state.isSelected ? "blue" : "white",
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    color: "white",
                    // backgroundColor: "black",
                    backgroundColor: "black",
                  }),
                  control: (provided, state) => ({
                    ...provided,
                    color: "white",
                    backgroundColor: "black",
                    "::placeholder": {
                      color: "white",
                    },

                    border: "1px solid rgba(255, 255, 255, 0.755) !important",
                    ":hover": {
                      border: "1px solid rgba(255, 255, 255, 0.5) !important",
                    },
                  }),
                  singleValue: (provided, state) => ({
                    ...provided,
                    color: "white",
                    backgroundColor: "black",
                    "::placeholder": {
                      color: "white",
                    },
                  }),
                }}
                onChange={(e) => setPN(e?.value)}
              />
              {/* <div className={classNames(styles.search)}>
              <p>{t("search")}</p>
            </div> */}
            </div>
          </div>

          <div className={classNames(styles.infos, "col-12")}>
            <div className={classNames(styles.titlesection, "col-12")}>
              <p>Information Produit</p>
              <div>
                <img src={ink} alt="Ink" />
              </div>
            </div>
            <div className={classNames(styles.infoscontent, "col-12")}>
              <div className={classNames(styles.oneinfo, "col-12")}>
                <div className={classNames(styles.text)}>
                  <p>Produit :</p>
                </div>
                <div className={classNames(styles.input)}>
                  <input
                    type="text"
                    disabled
                    name=""
                    id=""
                    value={inkLabel?.name}
                  />
                </div>
              </div>
              <div className={classNames(styles.oneinfo, "col-12")}>
                <div className={classNames(styles.text)}>
                  <p>Type :</p>
                </div>
                <div className={classNames(styles.input)}>
                  <input
                    type="text"
                    disabled
                    name=""
                    id=""
                    value={inkLabel?.type}
                  />
                </div>
              </div>
              <div className={classNames(styles.oneinfo, "col-12")}>
                <div className={classNames(styles.text)}>
                  <p>Volume :</p>
                </div>
                <div className={classNames(styles.input)}>
                  <input
                    type="text"
                    disabled
                    name=""
                    id=""
                    value={inkLabel?.volume}
                  />
                </div>
              </div>
              <div className={classNames(styles.oneinfo, "col-12")}>
                <div className={classNames(styles.text)}>
                  <p>Date de production :</p>
                </div>
                <div className={classNames(styles.input)}>
                  <input
                    type="date"
                    value={productionDate}
                    onChange={onDateChange}
                    className={classNames(styles.inputDate)}
                  />
                </div>
              </div>
              <div className={classNames(styles.oneinfo, "col-12")}>
                <div className={classNames(styles.text)}>
                  <p>Date d'expiration :</p>
                </div>
                <div className={classNames(styles.input)}>
                  <p className={classNames(styles.dateText)}>
                    {inkLabel && expireDate && expireDate.toLocaleDateString()}
                  </p>
                </div>
              </div>
              <div className={classNames(styles.oneinfo, "col-12")}>
                <div className={classNames(styles.text)}>
                  <p>Etiquette SSD :</p>
                </div>
                <div className={classNames(styles.checkbox)}>
                  <input
                    type="checkbox"
                    checked={checked}
                    onChange={handleChecked}
                    className={classNames(styles.inputDate)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={classNames(styles.nbproduit, "col-12")}>
            <div className={classNames(styles.titlesection, "col-12")}>
              <p>Nombre d'élément produit :</p>
            </div>
            <div className={classNames(styles.nbproduitzone, "col-12")}>
              <div className={classNames(styles.text)}>
                <p>Nombre :</p>
              </div>
              <div className={classNames(styles.input)}>
                <input
                  type="number"
                  name=""
                  id=""
                  defaultValue={1}
                  onChange={(e) => {
                    setCopyNumber(parseInt(e.target.value));
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={classNames(styles.viewlabel, "col-6")}>
          <div className={classNames(styles.titlesection, "col-12")}>
            <p>Etiquette :</p>
          </div>
          <div className={classNames(styles.etiquettepreviewzone, "col-12")}>
            {generatedPdf ? (
              <embed
                id="pdf"
                title="pdf"
                src={generatedPdf + "#toolbar=0"}
                style={{ width: "100%", height: "100%" }}
              />
            ) : (
              <img src={defaultLabel} alt="Label" />
            )}
          </div>
          <div className={classNames(styles.generatezone, "col-12")}>
            <div
              onClick={() => {
                inkLabel && generateInk();
              }}
              className={classNames(
                styles.generate,
                "col-12",
                !inkLabel && styles.disabled
              )}
            >
              <img src={tagimg} alt="Tag img" />
              <p>Générer {!isNaN(copyNumber) ? copyNumber : 1} étiquette(s)</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Createlabel;

function dateWithDelay(
  delay: number,
  productionDate: Date,
  delayType: "day" | "month"
) {
  if (delayType === "day") {
    productionDate.setDate(productionDate.getDate() + delay);
  } else {
    productionDate.setMonth(productionDate.getMonth() + delay);
  }

  return productionDate;
}
